<template>
  <div class="profile">
    <nav-tab
        :list="[{title: lang ==='en'?'Company Profile':'公司介绍', link: '/about/profile'}, {title: lang === 'en'?'News':'新闻动态', link: '/about/news'}, {
        title: lang === 'en'?'Exhibition':'展会信息',
        link: '/about/information'
      }, {title: lang === 'en'?'Contact':'联系我们', link: '/about/contact'}]"
        :active="lang ==='en'?'Company Profile':'公司介绍'"></nav-tab>
    <static-page v-if="load" :header="header" :html="html" :title="lang ==='en'?'Company Profile':'公司介绍'"/>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import StaticPage from "@/components/static-page/static-page";
import {GetStaticPageDetailApi} from "@/api/static_page";
import NavTab from "@/components/nav-tab/nav-tab";
import {GetPostListApi} from "@/api/post";
import {getLang} from "@/utils/lang";

export default {
  name: "profile",
  components: {NavTab, StaticPage, SafeArea},
  data() {
    return {
      lang: getLang(),
      html: '',
      header: require("@/assets/about/about.jpg"),
      load: false,
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      GetPostListApi({categoryId: 5, page: 1}).then(res => {
        this.load = true
        this.html = res.result[0].content
      })
    }
  }
}
</script>

<style scoped>

</style>
